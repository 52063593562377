import React, { useEffect, useState, useContext, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import { a, useSpring } from "react-spring";
import query from "./query.js";
import Link from "components/link";
import useIsMobile from "helpers/useIsMobile";
import MenuIcon from "./menu-icon";
import { ReactComponent as Logo } from '../../../src/assets/fh_logo_white.svg';
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Minus } from "../../assets/minus.svg";
import { AppContext } from "appContext";
import "./navigation.scss";

const breakpoint = 1250;

//	TODO
//	- Add Alert Banner above navigation
//
const Navigation = () => {
  const isMobile = useIsMobile(breakpoint);
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState(false)
  const navigationContext = useContext(AppContext);

  const { loading, data } = useQuery(query);

  const openDropdown = (id, el) => {
    setOpen(id)
    if (el.hasAttribute("aria-haspopup")) {
      el.setAttribute("aria-expanded", "true")
    }
  }

  const scrollHandler = () => {
    let scrollPos = window.scrollY

    if (scrollPos >= 300) {
      document.body.style.marginTop = `var(--nav-height-collapsed)`
      setCollapse(true)
    } else {
      document.body.style.marginTop = `var(--nav-height)`
      setCollapse(false)
    }
  }

  useEffect(() => {
    if (navigationContext.isReport) {
      document.body.style.marginTop = `0`
    } else {
      document.body.style.marginTop = `var(--nav-height-collapsed)`
    }
  }, [navigationContext])

  useEffect(() => {
    if (navigationContext.isReport) return
    window.addEventListener("scroll", scrollHandler)
    return () => window.removeEventListener("scroll", scrollHandler)
  }, [navigationContext])

  const closeDropdown = (el) => {
    setOpen(false)

    if (el.hasAttribute("aria-haspopup")) {
      el.setAttribute("aria-expanded", "false")
    }
  }

  const { height } = useSpring({
    height: collapse ? `var(--nav-height-collapsed)` : "var(--nav-height)"
  })

  if (loading || !data) {
    return <nav className={`${!isMobile && open ? "nav-open" : ""} site-nav`}>
      <a.div className="navInner" ref={ref} style={{ height }}>
        <a href="/" className="logo" aria-label="Fountain House Logo">
          <Logo />
        </a>
      </a.div>
    </nav>
  }

  return (
    <nav className={`${!isMobile && open ? "nav-open" : ""} ${navigationContext.isReport ? `reportNav` : `site-nav`} ${navigationContext.alertbar ? `hasAlert` : ``}`}>
      <a.div className="navInner" ref={ref} style={{ height }}>
        <a href="/" className="logo" aria-label="Fountain House Logo">
          <Logo />
        </a>
        {isMobile ? <MobileNav items={data.nav.builder} open={open} setOpen={setOpen} /> :
          <ul className={`navLinks ${data.nav.builder.length > 6 ? "widen" : ""}`}>
            {data.nav.builder.map(
              ({ id, linkTo, children }) => {
                if (linkTo.text === 'Search') {
                  return (
                    <li key={id} className={`listItem`}>
                      {linkTo && linkTo.element ?
                        <Link navLink={true} className={`navItem searchIcon`} {...linkTo} aria-label="Search" />
                        : ""}
                    </li>
                  );
                } else if (linkTo.text === 'Donate') {
                  return (
                    <li key={id} className={`listItem`}>
                      {linkTo ? <Link url={linkTo.url} className={`navItem donateBtn`} {...linkTo} /> : ""}
                    </li>
                  );
                } else {
                  return children.length ? <li
                    key={id}
                    className={`listItem has-children ${open === id ? "active" : ""} `}
                    onMouseOver={(e) => { openDropdown(id, e.target) }}
                    onMouseOut={(e) => { closeDropdown(e.target) }}
                    onKeyUp={(e) => { openDropdown(id, e.target) }}
                    onBlur={(e) => { closeDropdown(e.target) }}
                  >
                    {linkTo ? <Link
                      navLink={true}
                      className={`navItem`}
                      aria-haspopup="true"
                      aria-expanded="false"
                      {...linkTo}
                    /> : <button className={`navItem`}
                      aria-haspopup="true"
                      aria-expanded="false">{linkTo.text}</button>
                    }
                    <div className={`dropdown ${open && open === id ? "open" : "closed"} `}>
                      <ul className={`dropdown-wrapper ${children.length > 4 ? "wider" : ""} `} id={id}>
                        {children.map(link => <li key={link.id} className={`dropdown-item`}>
                          {linkTo ? <Link navLink={true} className={`dropdown-navItem`} {...link.linkTo} /> : ""}
                        </li>)}
                      </ul>
                    </div>
                  </li> :
                    <li key={id} className={`listItem`}>
                      {linkTo && linkTo.element ? <Link
                        navLink={true}
                        className={`navItem`}
                        {...linkTo}
                      /> : ""}
                    </li>
                }
              }
            )}
          </ul>
        }
      </a.div>
    </nav >
  );
};

const MobileNav = ({
  items,
  open,
  setOpen
}) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    document.body.style.overflow = active ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [active]);
  const closeMenu = () => setActive(false);
  return (
    <div className={`mobileNav ${active ? "active" : ""} `}>
      <div className="mobileNav-toggleph">
        <button className="mobileNavToggle" onClick={() => setActive(!active)} aria-label="Mobile Nav Menu Toggle Button">
          <MenuIcon active={active} />
        </button>
      </div>
      {active && items && (
        <MobileNavItems items={items} closeMenu={closeMenu} open={open} setOpen={setOpen} />
      )}
    </div>
  );
};

const MobileNavItems = ({
  items,
  closeMenu,
  open,
  setOpen
}) => {
  // const navigationContext = useContext(AppContext);
  const [height, setHeight] = useState(0);

  const openDropdown = (id, el) => {
    let grow = id !== open && el
    let elHeight = el.nextElementSibling.firstElementChild

    setHeight(grow ? elHeight.getBoundingClientRect().height + 16 : 0)
    setOpen(id === open ? false : id)
  }

  return (
    <div className={"mobileNav-wrapper"}>
      <ul className="mobileNav-links">
        {items.map(({ linkTo, id, children, ...rest }) => {
          if (linkTo.text === 'Search') {
            return (
              <li key={id}>
                {linkTo && linkTo.element ?
                  <Link className={`navItem`} onClick={closeMenu} {...linkTo} aria-label="Search" />
                  : ""}
              </li>
            );
          } else if (linkTo.text === "Donate") {
            return (
              <li key={id}>
                {linkTo ? <Link className={`navItem donateBtn`} onClick={closeMenu} {...linkTo} /> : ""}
              </li>
            );
          } else {
            return children.length ?
              <li key={id} className={`mobileNav-accordion`}>
                <button className={`navItem mobileNav-label`} onClick={(e) => { openDropdown(id, e.currentTarget) }}>
                  <span className={`navItem`}>{linkTo.text}</span>
                  {open && open === id ? <figure className="accordionSvg"><Minus /></figure> : <figure className="accordionSvg"><Plus /></figure>}
                </button>
                <div className={`mobileNav-dropdown ${open && open === id ? "open" : "closed"} `} style={open && open === id ? { height } : {}}>
                  <ul className={`dropdown-wrapper ${children.length > 4 ? "wider" : ""} `} id={id}>
                    <li className={`mobileNav-dropdown-item`}>
                      {linkTo && linkTo.element ? <Link className={`mobileNav-dropdown-navItem navItem`} {...linkTo} /> : ""}
                    </li>
                    {children.map(link => <li key={link.id} className={`mobileNav-dropdown-item`}>
                      {linkTo && linkTo.url ? <Link className={`mobileNav-dropdown-navItem navItem`} onClick={closeMenu} {...link.linkTo} /> : ""}
                    </li>)}
                  </ul>
                </div>
              </li> : (
                <li key={id}>
                  {linkTo && linkTo.element ? <Link className={`navItem`} onClick={closeMenu} {...linkTo} /> : ""}
                </li>
              );
          }

        })}
      </ul>
    </div>
  );
};

export default Navigation
